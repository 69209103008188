export interface MissingFields {
    name: boolean;
    orgunit: boolean;
}

export interface NewPrerequisiteObj {
    name: string;
    description: string;
    orgunit: string;
    orgunit_id: number | null;
    orgunit_idPath: string | null;
    orgunit_accessIdPath: string | null;
    status: string;
    status_id: number | null;
    comments: string;
    validityDescription: string;
    significanceForRisk: string;
    object: string;
    followUpResp: string;
    followUpResp_id: number | null;
    followUpDate: Date | null;
    valid: boolean;
}